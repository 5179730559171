import { useEffect } from 'react';
import { useRouter } from 'next/navigation';

import {
  APP_LANGUAGE_COOKIE_NAME,
  APP_SUPPORTED_LANGUAGES,
  APP_DEFAULT_LANGUAGE,
} from '../../configs/app.config';

export const useDefaultLanguageRedirect = (): void => {
  const router = useRouter();

  useEffect(() => {
    const detectBrowserLanguage = () => {
      if (typeof window !== 'undefined') {
        const browserLang = navigator.language;
        return browserLang.split('-')[0];
      }

      return undefined;
    };

    const storedLanguage = localStorage.getItem(APP_LANGUAGE_COOKIE_NAME);
    const detectedLanguage = storedLanguage ?? detectBrowserLanguage();

    if (detectedLanguage && APP_SUPPORTED_LANGUAGES.includes(detectedLanguage)) {
      router.push(`/${detectedLanguage}/report`);
    } else {
      router.push(`/${APP_DEFAULT_LANGUAGE}/report`);
    }
  });
};
