'use client';

import { Box, Grid, CircularProgress } from '@mui/material';

import { useDefaultLanguageRedirect } from '../hooks/useDefaultLanguageRedirect/useDefaultLanguageRedirect';

// Redirect the user to the default locale when `/` is requested
const RootPage = () => {
  useDefaultLanguageRedirect();

  return (
    <Grid item xs={12} component="main">
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: { xs: 2, md: 4 } }}>
        <CircularProgress />
      </Box>
    </Grid>
  );
};

export default RootPage;
